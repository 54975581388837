<template>
  <div class="main">
    <div class="actions cl">
      <div class="fl">
        <div class="title">{{$t('Order status')}}：確認訂單</div>
        <div>{{$t('Group status')}}：已成團</div>
      </div>
      <div class="fr">
        <button class="button m-r" type="button" @click="cancelOrder">{{$t('Cancel order')}}</button>
        <button class="button m-r" type="button">{{$t('Group application')}}</button>
        <button class="button button-primary" type="button">{{$t('Send a group notice')}}</button>
      </div>
    </div>
    <div class="fl-group">
      <div class="hd cl">
        <a class="fr btn" href="javascript:;" @click="openModal('contactClients')">{{$t('Contact customer')}}</a>
        {{$t('Order content')}}
      </div>
      <div class="order-info">
        <div class="p">{{$t('Order number')}}：001</div>
        <div class="p">{{$t('ProductNumber')}}：123</div>
        <div class="p">{{$t('Travel group name')}}：中國南京4天團</div>
        <div class="p">{{$t('Travel group date')}}：2019年1月1日 至 2019年1月1日</div>
        <div class="p">{{$t('Number of people')}}：4</div>
        <div class="p">{{$t('Order Source')}}：平台</div>
      </div>
    </div>
    <div class="fl-group">
      <div class="hd cl">
        <div class="fr">
          <!--          <a class="btn btn-gray" href="javascript:;">{{$t('Abandon Modification')}}</a>-->
          <!--          <a class="btn" href="javascript:;">{{$t('Save Changes')}}</a>-->
          <a class="btn" href="javascript:;">{{$t('Modify Information')}}</a>
        </div>
        {{$t('Traveler information')}}
      </div>
      <div class="table border">
        <table>
          <thead>
          <tr>
            <th></th>
            <th>旅客類型</th>
            <th>{{$t('Appellation')}}</th>
            <th>{{$t('English name')}}</th>
            <th>{{$t('Gender')}}</th>
            <th>{{$t('Certificate Type')}}</th>
            <th>{{$t('Certificate of Issue')}}</th>
            <th>{{$t('Certificate Number')}}</th>
            <th>{{$t('Certificate Validity Period')}}</th>
            <th>{{$t('DateOfBirth')}}</th>
            <th>{{$t('Mobile Number')}}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="i in 3" :key="i">
            <td>旅客1</td>
            <td>成人－佔半房</td>
            <td>Mr.</td>
            <td>Chan/Ka Wai</td>
            <td>男</td>
            <td>護照</td>
            <td>中國</td>
            <td>H7286437823</td>
            <td>2020年7月1日</td>
            <td>23-08-1989</td>
            <td>(852) 78612112</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="fl-group">
      <div class="hd cl">
        <a class="fr btn" href="javascript:;">{{$t('Modify Information')}}</a>
        {{$t('ContactInformation')}}
      </div>
      <div class="table border">
        <table>
          <thead>
          <tr>
            <th>{{$t('Contact Name')}}</th>
            <th>{{$t('Appellation')}}</th>
            <th>{{$t('Contact Number')}}</th>
            <th>{{$t('Email')}}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>OU／ben</td>
            <td>MR</td>
            <td>中國香港（852）673893677</td>
            <td>benmail@gmail.com</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="fl-group">
      <div class="hd">{{$t('Detailed Charge')}}</div>
      <div class="table border">
        <table>
          <thead>
          <tr>
            <th>{{$t('Charging items')}}</th>
            <th>{{$t('Price')}}</th>
            <th>{{$t('Quantity')}}</th>
            <th>{{$t('Amount')}}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="i in 3" :key="i">
            <td>成人</td>
            <td>HKD 2,000</td>
            <td>2</td>
            <td>HKD 4,000</td>
          </tr>
          </tbody>
          <tfoot>
          <tr class="b">
            <td colspan="3">{{$t('Total')}}</td>
            <td>HKD 8,000</td>
          </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'CancelOrderDetail',
    data() {
      return {};
    },
    created() {
    },
    methods: {
      cancelOrder() {
        this.$msgbox({
          title: this.$t('Cancel order'),
          message: '你需要取消當前的訂單嗎？請填寫',
          confirmButtonText: '取消及退款表',
          confirmButtonClass: 'center',
          lockScroll: false
        }).then(() => {
          this.$router.push({name: 'AdminCancelOrderApplication'});
        }).catch(() => {
        });
      }
    }
  };
</script>
<style scoped lang="less">
  @import "../../../assets/style/table";
  .main{
    .title{font-size:24px;font-weight:bold;}
    .actions{
      padding-bottom:20px;border-bottom:1px dashed #ff6f61;
      .button{
        min-width:80px;padding-left:8px;padding-right:8px;
        &.m-r{margin-right:8px;}
      }
    }
    /deep/ .table{margin-top:0;padding-bottom:0;}
  }
  .fl-group{
    .hd{
      position:relative;margin:20px 0;padding-left:16px;font-size:24px;
      &:after{position:absolute;top:50%;left:0;width:6px;height:20px;margin-top:-10px;background-color:#ff6f61;content:'';}
    }
    .btn{
      display:inline-block;margin-top:3px;margin-left:8px;padding:0 8px;line-height:28px;color:#1890ff;font-size:14px;border:1px solid #1890ff;border-radius:4px;
      &.btn-gray{color:#999;border-color:#ccc;}
    }
  }
  .order-info{
    padding:20px;background:#f4f4f4;border-radius:4px;
    .p{
      margin-bottom:16px;
      &:last-child{margin-bottom:0;}
    }
  }
</style>
